<template>
  <v-main class="mt-8 ml-4">
    <v-container>
      <slot></slot>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MainContent',
  created() {
    this.loadAll()
  },
  methods: {
    ...mapActions({ loadAll: 'ly2/loadAll' }),
  },
}
</script>
