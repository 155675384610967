<template>
  <v-navigation-drawer :class="navClass" app clipped :width="navWidth">
    <v-btn
      @click="toggleNavigationDrawer"
      small
      color="white"
      class="elevation-0 rounded-0 my-2"
    >
      <v-icon>{{ navIcon }}</v-icon>
    </v-btn>
    <div class="pl-12">
      <slot></slot>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    widthClose: 48
  }),
  props:  {
    widthOpen: {
      type: Number,
      default: 350,
    },
    alwaysScroll: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    navWidth: function () {
      return this.$store.state.isParamsPanelOpen
        ? this.widthOpen
        : this.widthClose
    },
    navIcon() {
      return this.$store.state.isParamsPanelOpen
        ? 'mdi-chevron-left'
        : 'mdi-playlist-edit'
    },
    navClass() {
      /* Есть спецаильные библиотеки для работы с классами, но пока так*/
      let classString = '';
      if (!this.$store.state.isParamsPanelOpen) classString += 'v-navigation-drawer--collapsed '
      if (this.alwaysScroll) classString += 'v-navigation-drawer--always-scroll '
      return classString.trim()
    }
  },
  methods: {
    toggleNavigationDrawer() {
      this.$store.commit('changeParamsPanelState')
    },
  },
}
</script>

<style scoped>
  /* порядок важен */
  .v-navigation-drawer--always-scroll >>> .v-navigation-drawer__content {
    overflow-y: scroll;
  }
  .v-navigation-drawer--collapsed >>> .v-navigation-drawer__content {
    overflow-y: hidden;
  }
</style>
